import { computed, ref } from "vue";
import { defineStore } from 'pinia'

export const useScreenState = defineStore('screen', () => {

  const screen= ref({
    isMobile: false,
    isHamburgerMenuOpen: false
  });

  const checkMobile = () => {
    screen.value.isMobile = window.innerWidth < 992
  }
  const toggleMenu = () => {
    screen.value.isHamburgerMenuOpen = !screen.value.isHamburgerMenuOpen;
  }

  const getScreen = computed(() => screen.value);

  return {
    getScreen,
    checkMobile,
    toggleMenu
  }
});
